.selector {
    width: 100%;
    position: relative;
}

.selectField {
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.selectField-labelimg {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


.selectField p {
    margin: 0 !important;
}

.selectField span {
    margin: 0 !important;
}

.selectField img {
    width: 12px;
    margin-right: 12px;
}

.selectField-labelimg img {
    width: 24px;
}

.list {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 !important;
    position: absolute;
    z-index: 5;
}

.options {
    width: 100%;
    padding: 15px 0 15px 70px;
    list-style: none;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.options img {
    width: 25px;
    position: absolute;
    left: 25px;
}

.options:hover {
    background: rgba(0, 0, 0, 0.2);
}

.hide {
    visibility: hidden;
}