body {
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
  color: black !important;
}

.tela {
  min-height: 100vh;
  min-width: 100vw;
}

.cp {
  cursor: pointer
}

.circle {
  width:  30px;
  height:  30px;
  background-color: black;
  border: none;
}

.hrCircle {
  width: 80px;
}

.none {
  display: none;
}

.imgLogo {
  width: 200px;
}

.imgLogoHeader {
  width:  100px;
}

.accordion-header:is(.fw-bolder) button{
  font-weight: bolder !important;
}